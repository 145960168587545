<template>
  <div v-if="pageData !== null" style="background-color: #fff; min-height: 100vh">
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <div class="text-xs-center" v-if="loading_inicial">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading_inicial">
      <v-container fluid style="padding:3%; background: #fff">
        <v-layout row wrap>
          <v-flex md4 style="text-align: center" class="pb-2 pt-2">
            <img width="200" src="../../assets/img/cartao/planet.svg" />
          </v-flex>
          <v-flex md8 style="text-align: center;" class="mt-4">
            <h1 class="pb-3 font-weight-bold display-1">
              <span class="hidden-sm-and-down" style="vertical-align: middle">
                <img width="70" src="../../assets/enercred-logo-quadrado.png" />
              </span>BEM VINDO(A)
            </h1>
            <p class="headline pb-3">Nosso time está alocando você em uma de nossas usinas.</p>
            <p class="title pb-2">Baixe nosso aplicativo e acompanhe os próximos passos.</p>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.enecred.enercredapp"
                target="_blank"
                class="pl-2 pr-2"
              >
                <img style="max-width: 150px;" :src="require('@/assets/img/apps/android.png')" />
              </a>
              <a
                href="https://apps.apple.com/us/app/enercred/id1465641135"
                target="_blank"
                class="pl-2 pr-2"
              >
                <img style="max-width: 150px" :src="require('@/assets/img/apps/app-store.png')" />
              </a>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <!-- Sessao Cadastrar Cartao -->
      <!--
      <v-container fluid :style="'padding:3%; background: ' + pageData.backgroundColor">
        <v-layout row wrap>
          
          <v-flex md6 style="text-align: center" class="hidden-md-and-up pb-5 pt-5">
            <img width="200" :src="pageData.logoUrl" />
          </v-flex>

          <v-flex md6 :style="'text-align: center; color: ' + pageData.textColor">
            <h1 class="display-1 pb-3 font-weight-bold">{{pageData.tagline}}</h1>
            <p class="title pb-2 pl-4 pr-4" style="text-align: left">
              Adicione seu cartão agora e ganhe mais
              <span class="font-weight-bold">R$ 15,00</span> na primeira fatura.
            </p>
            <p
              class="pl-4 pr-4 font-weight-bold"
              :style="'color:  '+pageData.subTextColor+'; text-align: left'"
            >Fique tranquilo! Você não paga nada até os descontos chegarem na sua conta.</p>
            <v-btn
              large
              :style="'background:' + pageData.buttonColor +'; border-radius: 50px;'"
              class="mt-4"
              @click="adicionarCartao()"
            >ADICIONAR AGORA</v-btn>
          </v-flex>
          <v-flex md6 style="text-align: center" class="hidden-sm-and-down pt-4">
            <img width="200" :src="pageData.logoUrl" />
          </v-flex>
        </v-layout>
      </v-container>
      -->

      <!-- Sessao Convidar Amigos -->
      <v-container fluid style="padding:3%; background: #095F96; color: #FFF;">
        <v-layout row wrap>
          <v-flex md4 style="text-align: center" class="pb-5 pt-3">
            <img width="200" src="../../assets/img/convidar-amigos-verde.svg" />
          </v-flex>
          <v-flex md8 style="text-align: center">
            <h1 class="pb-3 font-weight-bold display-1">CONTA PRA TODO MUNDO</h1>
            <p class="headline pb-2">É muito legal economizar e ajudar o planeta.</p>
            <p class="pb-2">
              Convide seus amigos, você e eles ganham
              <span class="font-weight-bold">mais R$ 50,00</span> na primeira fatura.
            </p>
            <p class="headline font-weight-bold">Convide amigos pelo:</p>

            <a :href="referralFacebook" target="_blank">
              <img style="margin: 15px" width="50" src="../../assets/icons/facebook.svg" />
            </a>
            <a :href="referralWhatsapp" target="_blank">
              <img style="margin: 15px" width="50" src="../../assets/icons/whatsapp-verde.svg" />
            </a>
            <a :href="referralGmail" target="_blank">
              <img style="margin: 15px" width="50" src="../../assets/icons/gmail.svg" />
            </a>
            <br />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { firestore } from "../../firebase/firestore"
export default {
  mounted() {
    this.bodyText =
      "Como+é+bom+ser+indicado+por+um+amigo!%0AEu+estou+economizando+na+minha+conta+de+luz+e+de+quebra+ajudando+o+planeta.%0AVocê+também+pode+fazer+parte+disso.%0AAcesse+o+link+abaixo+para+saber+mais+e+garantir+seu+desconto:%0Ahttps://onboard.enercred.com.br/indicacao?referral_code=" +
      this.user.email
    this.referralWhatsapp = `https://api.whatsapp.com/send?text=${this.bodyText}`
    this.referralFacebook = `https://www.facebook.com/sharer/sharer.php?u=onboard.enercred.com.br/indicacao?referral_code=${this.user.email}`
    this.referralGmail = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to&su=Economize+você+também+10%25+na+conta+de+luz+com+a+Enercred&body=${this.bodyText}&ui=2&tf=1&pli=1`
  },
  computed: {
    user() {
      return this.$store.getters["getUser"]
    },
    ...mapGetters(["getUser"])
  },
  created() {
    let url = window.location.origin
    let subdomain = url.slice(url.lastIndexOf("/") + 1).split(".")[0]
    if (subdomain !== "onboard" && subdomain !== "localhost:8080") {
      // get partner page data from firestore
      firestore
        .doc("partners/" + subdomain)
        .get()
        .then(partnerPageData => {
          this.pageData = partnerPageData.data().style.boasvindas
        })
    } else {
      firestore
        .doc("partners/enercred")
        .get()
        .then(enercredPage => {
          this.pageData = enercredPage.data().style.boasvindas
        })
    }
  },
  data() {
    return {
      loading_inicial: false,
      loading: false,
      bodyText: "",
      referralWhatsapp: "",
      referralFacebook: "",
      referralGmail: "",
      subdomain: "",
      pageData: null
    }
  },
  methods: {
    adicionarCartao() {
      this.$router.push("cadastro-cartao")
    }
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 10% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}
</style>
